  /* zoom Sdk Styles  */


  body {
      overflow: scroll !important;
      cursor: default;
  }

  #zmmtg-root {
      display: none;
      min-width: 0 !important;
  }


  #wc-container-left {
      margin-top: 120px !important;
  }

  #wc-content {
      margin-top: 70px !important;
  }

  #wc-footer {
      bottom: 70px !important;
  }

  main {
      width: 70%;
      margin: auto;
      text-align: center;
  }

  main button {
      margin-top: 20px;
      background-color: #2D8CFF;
      color: #ffffff;
      text-decoration: none;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 40px;
      padding-right: 40px;
      display: inline-block;
      border-radius: 10px;
      cursor: pointer;
      border: none;
      outline: none;
  }

  /* main button:hover {
    background-color: #2681F2;
  } */

  input:focus {
      outline: none !important;
  }

  .react-tel-input .form-control:focus {
      background-color: #fff;
      border-color: #3788d8 !important;
      box-shadow: 0 0 0 0
  }

  .react-tel-input .form-control:hover {
      border-color: black !important;
  }

  /* zoom Sdk Styles  */

  @font-face {
      font-family: palatino;
      src: url('../fonts/palatino-linotype/Palatino\ Linotype.ttf');
  }



  /* timeslots hide start  */


  /* .rbc-btn-group {
  } */

  .rbc-btn-group:nth-child(1) {
      /* Your styles here */
      display: none !important;

  }

  .rbc-calendar {
      height: fit-content !important;
  }

  .rat-TimeSlot_title {
      font-size: 20px;
  }


  .rbc-month-row+.rbc-month-row, .rbc-month-view, .rbc-header+.rbc-header {
      border: none !important;
  }

  .rbc-header {
      border-bottom: none !important;
  }

  .rbc-row-bg {
      display: block !important;
  }

  .rbc-row {
      margin-bottom: 9px !important;
  }




  .rbc-date-cell {
      min-height: 30px !important;
      min-width: 30px !important;

      height: '37px';
      width: '37px';
      background: #E8F5FF;
      border-radius: 7.206px 7.869px !important;
      text-align: center;
      display: grid;

  }

  .rbc-btn-group>button:nth-child(4) {
      display: none !important;
  }

  .rbc-date-cell .rbc-button-link {
      font-size: 14px !important;
      font-weight: 400 !important;
      font-family: 'Montserrat', sans-serif !important;
  }

  .rbc-toolbar-label {
      font-family: 'Montserrat', sans-serif !important;


      font-size: 16px !important;
      font-weight: 400 !important;
      margin-bottom: 20px;
  }

  .rbc-row {
      column-gap: 20px;
  }

  .rbc-time-view {
      background: white;
      z-index: 100;
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {

      .rbc-row {
          column-gap: 10px !important;
      }
  }


  @media screen and (max-width: 768px) {
      .rbc-row {
          column-gap: 10px !important;
      }

      .rbc-month-view {
          padding-left: 10px !important;
          padding-right: 10px !important;
      }

      .fc-event-main strong {
          writing-mode: vertical-rl !important;
          transform-origin: center !important;
          transform: scale(0.7) !important;
          top: 0 !important;
          left: 0 !important;
      }
  }

  .rbc-month-row {
      display: flex;
      position: relative;
      flex-direction: column;
      flex: 0 !important;
      flex-basis: 0px;
      overflow: inherit !important;
      height: inherit !important;

  }

  /* timeslots hide end  */

  input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #878D89;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;

  }

  /* timeslots grid calendar global styles  */

  .timeslots-calendar .rbc-time-view {
      border-radius: 19px;
      background: #FFF;

      /* General Shadows/Shadow 01 */
      box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
      border: none;

  }

  .timeslots-calendar .rbc-allday-cell {
      display: none;
  }

  .timeslots-calendar .rbc-time-header-cell {
      margin-bottom: 0 !important;
      height: 50px !important;
  }

  .timeslots-calendar .rbc-today {
      background: white !important;
  }

  .timeslots-calendar .rbc-time-header-cell .rbc-header {
      display: grid !important;




  }

  .timeslots-calendar .rbc-time-header-cell .rbc-header button div, .timeslots-calendar .rbc-timeslot-group .rbc-time-slot .rbc-label, .timeslots-calendar .rbc-events-container .rbc-event-content, .timeslots-calendar .rbc-events-container .rbc-event-content {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      color: #282A39;
      font-weight: 500;
  }

  .timeslots-calendar .rbc-time-header-gutter, .timeslots-calendar .rbc-time-gutter {
      min-width: 160px !important;
  }

  .timeslots-calendar .rbc-timeslot-group .rbc-time-slot {
      display: grid;
      justify-content: center;
      align-items: center;
  }


  .timeslots-calendar .rbc-event-content {
      flex: inherit !important;
      height: auto !important;
      min-height: inherit !important;
      line-height: auto;
  }

  .timeslots-calendar .rbc-events-container .rbc-event {
      justify-content: center !important;
  }

  .timeslots-calendar .rbc-events-container .rbc-event {
      border-radius: 0 !important;
  }

  .timeslots-calendar .rbc-events-container .rbc-event-label {
      margin-left: auto;
      margin-right: auto;
      font-size: 10px;
      color: white;
      font-weight: 500;
  }

  .timeslots-calendar .rbc-events-container .rbc-event-content {
      text-align: center;
      margin-top: 8px;
      color: white;
      font-size: 16px;
  }

  .timeslots-calendar .rbc-events-container {
      margin-right: 0 !important;
  }





  #participants-ul {
      margin-top: 50px !important;
  }

  #wc-container-right {
      margin-top: 119px !important;
  }

  #zoom-modal {
      display: none;

      position: absolute;
      top: 0;
      z-index: 100000000000;
      width: 90%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: opacity 0.3s ease-in-out;

  }

  /* timeslots grid calendar global styles  */




  /* new time slots calendar css  */

  .fc-daygrid-body-natural, .fc-header-toolbar .fc-toolbar-chunk:first-child {
      display: none;
  }

  .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
      display: none;
  }


  .fc-media-screen {
      height: 700px !important;
  }

  .input-phone {
      font-family: 'Montserrat', sans-serif !important;


      font-size: 16px !important;
      font-weight: 400 !important;
      height: '56px';
      width: '100%';
      border: '1px solid #DADADA'
  }

  .react-tel-input ::-webkit-input-placeholder {
      color: black;
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 400;
  }

  .fc-header-toolbar {
      justify-content: center !important;
  }

  .fc-col-header-cell .fc-scrollgrid-sync-inner a {
      font-size: 14px;
      color: black;
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 500;
      padding: 20px 0 20px 0;
  }

  .fc-col-header th {
      border: none !important;
  }

  .fc-timegrid-slot .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
      font-size: 13px !important;
      font-weight: 500 !important;
  }

  .fc-event-main {
      position: relative;
  }


  .fc-event-main strong {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }


  .fc-event-main strong {
      min-width: max-content !important
  }


  #events-calendar .rbc-time-header-cell {
      column-gap: 5px !important;
  }

  #events-calendar .rbc-time-header-cell .rbc-header button {
      font-size: 12px !important;
  }

  #events-calendar .rbc-time-header-cell .rbc-header {
      padding: 0 !important;
  }

  #events-calendar .rbc-time-header-content .rbc-allday-cell {
      display: none !important;

  }



  @media screen and (max-width: 768px) {

      #apexchartsms-bar,
      .mixed-chart>div:first-child:not([class]) {
          min-width: 700px;
          /* Set minimum width */
      }
  }



  /* new time slots calendar css  */